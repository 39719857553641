* {
  font-family: 'Open Sans', sans-serif;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
}

html,
body {
  scroll-behavior: smooth;
}

body > iframe {
  display: none;
}

#role.MuiInputBase-input {
  text-transform: capitalize;
}

#name.MuiInputBase-input {
  text-transform: capitalize;
}

#address.MuiInputBase-input {
  text-transform: capitalize;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.pbrain__logo-div {
  bottom: var(--pbrainBottom) !important;
  height: 54px !important;
  width: 54px !important;
  box-shadow: 0 2px 6px rgb(0 0 0 / 50%);
  right: var(--pbrainRight) !important;
}

.pbrain__holder-div {
  bottom: var(--pbrainBottom) !important;
  right: var(--pbrainRight) !important;
}

.pac-container {
  z-index: 10000 !important;
}

@media only screen and (min-height: 600px) and (min-width: 480px) {
  .pbrain__logo-div {
    height: 60px !important;
    width: 60px !important;
  }
}
.vertical-timeline-element-content {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0em;
  padding: 0 !important;
  border: 7px black !important;
}

.vertical-timeline-element {
  margin-top: 20px;
  margin-bottom: 20px;
}
.vertical-timeline::before {
  top: 40px;
  height: 80%;
}

.pbrain__holder-div {
  inset: var(--pbrainHolderInset) !important;
}

[type='button'].pbrain__logo-div {
  inset: var(--pbrainInset) !important;
}

.pbrain__shadow-div {
  left: var(--pbrainShadowLeft) !important;
  right: var(--pbrainShadowRight) !important;
}

/* For react-big-calendar, make the cursor a pointer to indicate it is clickable */
.rbc-agenda-event-cell {
  cursor: pointer;
}
.rbc-event {
  background-color: #1272e3;
}
.rbc-event-content {
  font-size: 14px;
}
.rbc-day-bg,
.rbc-month-header {
  background-color: white;
}

@keyframes pbrain__translateXShow {
  0% {
    display: block;
    transform: translateX(var(--pbrainTranslateXBegin));
  }
  to {
    transform: translateX(var(--pbrainTranslateXEnd));
  }
}

@keyframes pbrain__translateXHide {
  0% {
    transform: translateX(var(--pbrainTranslateXEnd));
  }
  to {
    display: none;
    transform: translateX(var(--pbrainTranslateXBegin));
  }
}
