:root {
  /* Taking reference from palette.js */
  --fontGrey: #9D9D9D;
}

.shepherd-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.shepherd-title {
  color: rgba(0,0,0);
  display: flex;
  flex: 1 0 auto;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  width: 200px;
  line-height: normal;
}

.shepherd-button-yellow {
  background: #f0c518;
  color: #000000 !important;
}

.shepherd-button-yellow:hover {
  background: #c7a933 !important;
}

.shepherd-has-title .shepherd-content .shepherd-header{
  background: white;
  padding-bottom: 0rem;
  padding-left: 0.75rem;
}

.shepherd-button:disabled {
  cursor: not-allowed;
  color: var(--fontGrey);
}

.step-number {
  color: var(--fontGrey) !important;
  font-size: 0.8rem;
  background-color: transparent !important;
  cursor: default;
}

/* Mobile view */
.mobile-positioning {
  left: 8px !important;
}

.shepherd-arrow::before {
  background-color: white !important;
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none !important;
}
